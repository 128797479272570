import { gql } from "@apollo/client";

export const GET_ALL_QUESTIONS = gql`
query get_questions($page: Int!, $perPage: Int) {
  getQuestions(page: $page,perPage: $perPage) {
    questions{
      answer{
        id
      }
      id
      text
      priority
      isAnswered
      isApproved
      isPinned
      image_urls
      category{
        name
      }
    }
    pageInfo{
      totalItems
      totalPages
      currentPage
    }
  }
}
`;


export const CREATE_QUESTION = gql`
mutation create_question($text: String!, $categoryId: ID, $priority: Int, $image_urls: [String!]) {
  adminCreateQuestion(text: $text, categoryId: $categoryId, priority: $priority, image_urls: $image_urls) {
    id
    text
    isAnswered
    isApproved
    priority
    image_urls
    category{
      id
      name
    }
  }
}
`;

export const GET_QUESTION = gql`
query get_question($id: ID!) {
  getQuestion(id: $id) {
      id
      text
      isAnswered
      isApproved
      priority
      image_urls
      category{
        id
        name
      }
  }
}
`;

export const UPDATE_QUESTION = gql`
mutation update_question($id: ID!, $text: String, $categoryId: ID, $priority: Int, $image_urls: [String!]!) {
  updateQuestion(id: $id, text: $text, categoryId: $categoryId, priority: $priority, image_urls: $image_urls) {
    id
    text
    isAnswered
    isApproved
    priority
    image_urls
    category{
      id
      name
    }
  }
}
`;

export const SET_QUESTION_PRIORITY = gql`
mutation update_question($id: ID!, $priority: Int) {
  updateQuestion(id: $id, priority: $priority) {
    id
    text
    isAnswered
    isApproved
    priority
    category{
      id
      name
    }
  }
}
`;

export const SET_QUESTION_PINNED = gql`
mutation adminSetPinnedQuestions($questions: [Json!]) {
  adminSetPinnedQuestions(questions: $questions)
}
`;

export const APPROVE_QUESTION = gql`
mutation approve_question($id: ID!, $categoryId: ID) {
  approveQuestion(id: $id, categoryId: $categoryId) {
    id
    text
    isAnswered
    isApproved
  }
}
`;

export const REJECT_QUESTION = gql`
mutation reject_question($id: ID!) {
  rejectQuestion(id: $id) {
    id
    text
    isAnswered
    isApproved
  }
}
`;

export const DELETE_QUESTION = gql`
mutation delete_question($id: ID!) {
  deleteQuestion(id: $id) {
    id
    text
    isAnswered
    isApproved
  }
}
`;


export const CREATE_ANSWER = gql`
mutation create_answer(
$questionId: ID!,
$plainText: String,
$rawHtml: String,
$meta: Json
) {
  createAnswer(questionId: $questionId,plainText: $plainText,rawHtml: $rawHtml,meta: $meta) {
    id
  }
}
`;

export const UPDATE_ANSWER = gql`
mutation updateAnswer(
$id: ID!,
$plainText: String,
$rawHtml: String,
$meta: Json
) {
  updateAnswer(id: $id,plainText: $plainText,rawHtml: $rawHtml,meta: $meta) {
    id
  }
}
`;

export const GET_ANSWER = gql` 
  query get_answer($id: ID!){
    getAnswer(id: $id){
      id
      plainText
      rawHtml
      meta
    }

  }
`


export const UPLOAD_IMG = gql`
mutation upload($images: [Upload!]) {
  upload(images: $images)
}
`;