
import { Route, Routes } from 'react-router';
import Login from '../Dashboard/auth/Login';
import QuestionsList from '../Dashboard/questions/QuestionsList';
import UsersList from '../Dashboard/users/UsersList';
import CategoriesList from '../Dashboard/categories/CategoriesList';
import AddUser from '../Dashboard/users/AddUser';
import AddQuestion from '../Dashboard/questions/AddQuestion';
import AddCategory from '../Dashboard/categories/AddCategory';
import AnswerQuestion from '../Dashboard/questions/AnswerQuestion';
import EditCategory from '../Dashboard/categories/EditCategory';
import EditUser from '../Dashboard/users/EditUser';
import EditQuestion from '../Dashboard/questions/EditQuestion';

export const protected_routes = [
    {path: "questions", element: <QuestionsList />},
    {path: "add-question", element: <AddQuestion />},
    {path: "edit-question/:id", element: <EditQuestion />},
    {path: "answer-question/:id", element: <AnswerQuestion />},
    {path: "users", element: <UsersList />},
    {path: "add-user", element: <AddUser />},
    {path: "edit-user/:id", element: <EditUser />},
    {path: "categories", element: <CategoriesList />},
    {path: "add-category", element: <AddCategory />},
    {path: "edit-category/:id", element: <EditCategory />},

]

export const public_routes = [
    {path: "login", element: <Login />},
]


