

import * as React from 'react';
import { Button, Dropdown, Form, Grid, Header, Icon, Modal } from 'semantic-ui-react'
import "../shared-components/DeleteModal.css"
import { APPROVE_QUESTION, GET_ALL_QUESTIONS } from '../graphql/questions';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_PARENT_CATEGORIES } from '../graphql/categories';

export default function ApproveModal({question, page}) {

  const [handleApprove,{loading: approveLoading,error: approveError, data: approveSuccess}] = useMutation(APPROVE_QUESTION,{
    refetchQueries: [
        { query: GET_ALL_QUESTIONS, variables: {page: page+1,perPage: 10} },
    ],
    awaitRefetchQueries: true
  })


  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if(approveSuccess){
      setOpen(false)
    }
  },[approveSuccess])

  const [parent, setParent] = React.useState(null);

  const { data: categoriesData } = useQuery(GET_ALL_PARENT_CATEGORIES);

  return (
    <Modal
    size="tiny"
    style={{margin: "0px !important"}}
      open={open}
      trigger={<Button color='green'>Approve</Button>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header content='Choose Category' />
      <Modal.Content>
        
      {categoriesData && 
          <Grid.Column>
              <Form.Field>
                  <label>Category</label>
                  <Dropdown 
                      placeholder='Select Category' 
                      onChange={(e,v) => {
                          setParent(v.value)
                      }}
                      search 
                      selection 
                      value={parent}
                      options={categoriesData.getRootCategories.map(c => {return {key: c.id, value: c.id, text: c.name}})} 
                  />
              </Form.Field>
          </Grid.Column>
      }

      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => setOpen(false)}>
          <Icon name='remove' /> Cancel
        </Button>
        <Button positive onClick={() => {
          handleApprove({variables: {id: question.id, categoryId: parent}})
        }}>
          <Icon name='checkmark' /> Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
