import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'
import HeaderMenu from './HeaderMenu'
import VerticalSidebar from './VerticalSidebar'


function Layout({children}) {
  
  const [visible, setVisible] = useState(false)

  return (
    
      <Sidebar.Pushable as={Segment} style={{ overflow: 'hidden', border: "0px", borderRadius: "0px" }}>
        <VerticalSidebar visible={visible} setVisible={setVisible} />
        <Sidebar.Pusher dimmed={visible} style={{height: "100vh", overflow: "auto"}}>
          <Segment basic style={{border: "0px", borderRadius: "0px", padding: "0px 0px 50px 0px", minHeight: "calc( 100vh - 90px )" }}>
            <HeaderMenu style={{border: "0px", borderRadius: "0px"}} handleToggleSidebar={() => {setVisible(!visible)}} visible={visible} />
            <div style={{padding: "0px 50px 0px 50px"}}>
              {children}
            </div>
            
          </Segment>
          <div style={{padding: "25px",background: "#f3f3f3"}}>Copyrights Reserved</div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
  )
}

export default Layout