import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery,useMutation } from '@apollo/client';
import { GET_ALL_CATEGORIES, DELETE_CATEGORY, GET_ALL_PARENT_CATEGORIES } from '../graphql/categories';
import DeleteModal from '../shared-components/DeleteModal';
import { Button } from 'semantic-ui-react';
import { Icon, Label, Menu, Table } from 'semantic-ui-react'
import { Pagination } from 'semantic-ui-react'
import ResultHandler from '../shared-components/ResultHandler';
import PinColumn from './PinColumn';
import TrendingColumn from './TrendingColumn';
const columns = [
  { id: 'id', label: 'Id', width: 1 },
  { id: 'name', label: 'Name', width: 8 },
  { id: 'isPinned', label: 'Pin', width: 1 },
  { id: 'isTrending', label: 'Trending', width: 1 },

  { id: 'action',label: 'Action',width: 2},
];

export default function CategoriesList() {

  const navigate = useNavigate()

  const [page, setPage] = React.useState(0);

  const handlePaginationChange = (e, { activePage }) => setPage(activePage-1)

  const { loading: categoriesLoading, error: categoriesError, data } = useQuery(GET_ALL_CATEGORIES,{variables: {page: page+1, perPage:10 }});

  const [handle_delete_category,{data: deleteSuccess, error: deleteError, loading: deleteLoading}] = useMutation(DELETE_CATEGORY,{
    refetchQueries: [
        { query: GET_ALL_PARENT_CATEGORIES },
        { query: GET_ALL_CATEGORIES, variables:{page: 1, perPage: 10}}
    ],
    awaitRefetchQueries: true
})

  return (
    <div className='page'>
      
      <div className='header'>
        <h1>Categories</h1>
        <Button variant="contained" onClick={() => {navigate('/add-category')}}>
          Add
        </Button>
      </div>

      { data?.getAllCategories.categories.length > 0 &&
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <Table style={{ maxHeight: 440 }} celled>
          <Table.Header>
            <Table.Row>
              {columns.map((column) => (
                <Table.HeaderCell
                  key={column.label}
                  width={column.width}
                >
                  {column.label}
                </Table.HeaderCell>
              ))}
              
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.getAllCategories.categories.map((row) => {
                return (
                  <Table.Row key={row.id}>
                    {columns.map((column) => {
                      if(!['action', 'isPinned', 'isTrending'].includes(column.id) ){
                        const value = row[column.id];
                        return (
                          <Table.Cell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </Table.Cell>
                        );
                      }
                    })}
                    <PinColumn category={row} page={page} />
                    <TrendingColumn category={row} page={page} />
                    <Table.Cell>
                        <Button color='blue' onClick={() => {navigate("/edit-category/"+row['id'])}}>
                          Edit
                        </Button>
                        <DeleteModal 
                          success={deleteSuccess}
                          handleConfirm={(e) => {
                            handle_delete_category({variables: {id: row['id']}})
                          }} 
                        />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <div style={{display: "flex",justifyContent: "end"}}>  
      <Pagination  
        activePage={page+1}
        onPageChange={handlePaginationChange}    
        totalPages={data.getAllCategories.pageInfo.totalPages} 
      />
    </div>
    </div>
      }

      <ResultHandler empty={data?.getAllCategories.categories.length === 0} emptyMsg="No Categories Found." error={categoriesError} loading={categoriesLoading} />

      
      
    </div>
    
  );
}
