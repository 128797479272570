import { useMutation,useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { CREATE_CATEGORY, GET_ALL_PARENT_CATEGORIES, GET_ALL_CATEGORIES } from "../graphql/categories";
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import { Button, Dropdown, Form, Grid, Select } from "semantic-ui-react";
import ResultHandler from "../shared-components/ResultHandler";


export default function AddCategory(){
    
    //const [parent, setParent] = React.useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        register("name", { required: true });
    }, []);

    const [storeCategory,{loading, error, data: success}] = useMutation(CREATE_CATEGORY,{
        refetchQueries: [
            { query: GET_ALL_PARENT_CATEGORIES },
            { query: GET_ALL_CATEGORIES, variables:{page: 1, perPage: 10}}
        ],
        awaitRefetchQueries: true
    })
    
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger
      } = useForm();

    const onSubmit = data => {
        storeCategory({variables:{
            name:data.name,
            //parentCategoryId: parent
        }})        
    };

    useEffect(() => {
        if( success ){
            navigate("/categories")
        }
    },[success])

    //const { data: categoriesData } = useQuery(GET_ALL_PARENT_CATEGORIES);

    return (
        <div className='page'>
            <div className='header'>
                <h1>Add Category</h1>
            </div>

            <Form loading={loading} onSubmit={handleSubmit(onSubmit)}>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Form.Input 
                                label="Name"
                                name="name"
                                placeholder='Name' 
                                error={ errors.name ? {
                                    content: 'Name is required',
                                } : false}  
                                onChange={async (e, { name, value }) => { 
                                    setValue("name", e.target.value);
                                    await trigger( name );
                                }}
                                style={{marginBottom: "15px"}}
                            />
                        </Form.Field> 
                    </Grid.Column>

                    {/*categoriesData && 
                    <Grid.Column>
                        <Form.Field>
                            <label>Parent Category</label>
                            <Dropdown 
                                placeholder='Select Category' 
                                onChange={(e,v) => {
                                    setParent(v.value)
                                }}
                                clearable 
                                search 
                                selection 
                                options={categoriesData.getParentCategories.map(c => {return {key: c.id, value: c.id, text: c.name}})} 
                            />
                        </Form.Field>
                    </Grid.Column>
                    */}
                    
                    <Grid.Column>
                        <ResultHandler error={error} success={success} successMsg="Stored Successfully." />
                    </Grid.Column>

                    <Grid.Column style={{display: "flex",justifyContent: "end"}}>
                        <Button type="submit">Create</Button>
                    </Grid.Column>

                </Grid.Row>

            </Form>

        </div>
    )

}