import React, { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import { convertFromRaw, convertToRaw, EditorState } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./Editor.css"
import UploadImageModal from "../media-manager/UploadImageModal"
export function RichEditorExample({defaultContent, onEditorStateChange}) {

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    if (defaultContent) {
      const DBEditorState = convertFromRaw(defaultContent)
      setEditorState(EditorState.createWithContent(DBEditorState))
    }
  }, [])

  function handlePastedText(text, html, editorState) {
    //console.log("test: ", convertToRaw(editorState.getCurrentContent()))
    //return true to block paste anything
  }

  return (
    <>
      <Editor
        toolbar={{
          options: [
            "inline",
            "textAlign",
            "blockType",
            //"fontSize",
            //"fontFamily",
            "list",
            //"colorPicker",
            "link",
            "embedded",
            "history",
            'image'
          ],
          image: {
            component: UploadImageModal
          },
        }}
        handlePastedText={handlePastedText}
        textAlignment="right"
        placeholder="Write Something"
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={editorState => {
          onEditorStateChange(editorState)
          setEditorState(editorState)
        }}
      />
    </>
  )
}
