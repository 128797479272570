
import { useMutation } from '@apollo/client';
import { Button, Table } from 'semantic-ui-react';
import { GET_ALL_CATEGORIES, SET_CATEGORY_TRENDING } from '../graphql/categories';

export default function TrendingColumn({category, page}){
    
  const [handleUpdate,{loading,error, data: success}] = useMutation(SET_CATEGORY_TRENDING,{
    refetchQueries: [
        {query: GET_ALL_CATEGORIES, variables: {page: page+1, perPage: 10}}
    ],
    awaitRefetchQueries: true
  })

    return(
        <Table.Cell>
            {category.isTrending &&
            
                <Button color='red' loading={loading} onClick={() => {
                    handleUpdate({variables: {ids: [category.id]}})
                }}>
                    Not Trend    
                </Button>
            
            }
            {!category.isTrending &&
                <Button color='green' loading={loading} onClick={() => {handleUpdate({variables: {ids: [category.id]}})}}>
                    Trending     
                </Button>
            }
        </Table.Cell>
    )
}