import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
query get_all_users($page: Int!, $perPage: Int,$type: String) {
  allUsers(page: $page,perPage: $perPage, type: $type) {
    users{
      id
      name
      email
    }
    pageInfo{
      totalItems
      totalPages
      currentPage
    }
  }
}
`;

export const CREATE_ADMIN = gql`
mutation create_admin($name: String!, $email: String!, $password: String!) {
  adminRegister(name: $name, email: $email, password: $password) {
    admin{
      id
      name
      email
    }
  }
}
`;

export const UPDATE_ADMIN = gql`
mutation update_admin($id: ID!,$name: String, $email: String, $password: String, $age: Int) {
  updateUser(id: $id, name: $name, email: $email, password: $password, age: $age) {
    id
    name
    email
  }
}
`;

export const GET_USER = gql`
query get_user($id: ID!){
  oneUser(id: $id){
    id 
    name 
    email
    password
  }
}

`