
import { useNavigate } from 'react-router'
import {
    Button,
    Checkbox,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
  } from 'semantic-ui-react'

  import "./Layout.css"


export default function VerticalSidebar({visible, setVisible}){

  const navigate = useNavigate()

  const animation = 'overlay'
  const direction = 'left'
  const dimmed = true

    return (
  <Sidebar
    as={Menu}
    animation={animation}
    direction={direction}
    icon='labeled'
    inverted
    vertical
    visible={visible}
    onHide={() => setVisible(false)}
    width='thin'
    className='mainSidebar'
    style={{paddingTop: "30px"}}
  >

    <Menu.Item header style={{textAlign: "left"}}>Esaal Dashboard</Menu.Item>

    <Menu.Item as='a' onClick={() => {navigate('/users')}}>
      <Icon name='users' />
      <span>
        Admins
      </span>
    </Menu.Item>
    <Menu.Item as='a' onClick={() => {navigate('/categories')}}>
      <Icon name='folder open outline' />
      <span>
      Categories
      </span>
    </Menu.Item>
    <Menu.Item as='a' onClick={() => {navigate('/questions')}}>
      <Icon name='question circle outline' />
      <span>
      Questions
      </span>
    </Menu.Item>
   
  </Sidebar>
    )
}