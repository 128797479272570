
export default function ResultHandler({error, success, successMsg, loading, empty, emptyMsg}){
    return(
        <>
            { success &&
                <div>{successMsg}</div>
            }
            { error &&
                <div>{error.message}</div>
            }
            { loading &&
                <div>loading...</div>
            }
            { empty &&
                <div>{emptyMsg}</div>
            }
        </>
    )
}