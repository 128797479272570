import { useEffect, useState } from "react";
import {RichEditorExample} from "./AnswerEditor";
import draftToHtml from 'draftjs-to-html';
import { useMutation, useQuery } from "@apollo/client";
import {CREATE_ANSWER, GET_ALL_QUESTIONS, GET_ANSWER, UPDATE_ANSWER} from "../graphql/questions"
import { useNavigate, useParams } from "react-router";
import { Button, Form, Grid } from "semantic-ui-react";
import { convertToRaw } from "draft-js";
import ResultHandler from "../shared-components/ResultHandler";


export default function AnswerQuestion(){

    const navigate = useNavigate()

    const params = useParams()

    let [htmlContent,setHtmlContent] = useState('')

    let [textContent,setTextContent] = useState('')

    let [jsonContent,setJsonContent] = useState([])

    /// create answer

    const [handle_answer, {data: success,loading, error: answerError}] = useMutation(CREATE_ANSWER,{
        variables: {questionId: params.id,plainText: textContent,meta :jsonContent, rawHtml: htmlContent},
        refetchQueries: [
            { query: GET_ALL_QUESTIONS, variables: {page: 1,perPage: 10} },
        ],
        awaitRefetchQueries: true
    })

    ///// update answer

    const [handle_update_answer, {data: updateSuccess,loading: updateLoading, error: updateError}] = useMutation(UPDATE_ANSWER,{
        variables: {id: params.id,plainText: textContent,meta :jsonContent, rawHtml: htmlContent},
        refetchQueries: [
            { query: GET_ALL_QUESTIONS, variables: {page: 1,perPage: 10} },
            { query: GET_ANSWER, variables: {id: params.id} },
        ],
        awaitRefetchQueries: true
    })

    /// gettting old answer
    const {data: answerData, loading: answerLoading, error: answerGetError} = useQuery(GET_ANSWER,{variables: {id: params.id}})

    // text editor

    const onEditorStateChange = (editorState) => {
        let meta = convertToRaw(editorState.getCurrentContent())
        let plain = editorState.getCurrentContent().getPlainText("\n")
        setTextContent(plain)
        setHtmlContent(draftToHtml(meta))
        setJsonContent(meta)

    }

    useEffect(() => {
        if(success || updateSuccess){
            navigate('/questions')
        }
    },[success, updateSuccess])
          

    return(
        <div className="page">
            <div className="header">
                <h1>Answer Question</h1>
            </div>
            <ResultHandler error={answerError || (answerGetError && answerData) || updateError} />
            <Form loading={loading || answerLoading || updateLoading}>
                { answerData &&
                    <>
                        <RichEditorExample defaultContent={answerData.getAnswer.meta} onEditorStateChange={onEditorStateChange} />
                    </>
                }
                { !answerData &&
                    <RichEditorExample defaultContent="" onEditorStateChange={onEditorStateChange} />
                }
            </Form>
            
            <div style={{display: "flex",justifyContent:"flex-end"}}>
                {!answerData && <Button onClick={handle_answer} style={{marginTop: "30px"}}>Post</Button>}
                {answerData && <Button onClick={handle_update_answer} style={{marginTop: "30px"}}>Update</Button>}
            </div>
        </div>
    )


}