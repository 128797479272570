import { useMutation, useQuery } from "@apollo/client";
import {UPDATE_ADMIN, GET_USER, GET_ALL_USERS} from "../graphql/users"
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import {useNavigate} from 'react-router-dom';
import { useEffect } from "react";
import ResultHandler from "../shared-components/ResultHandler";

import { Button, Form, Grid } from "semantic-ui-react";
export default function EditUser(){

    const navigate = useNavigate();

    let params = useParams()

    // get user data

    const {data: userData, loading: userLoading, error: userError} = useQuery(GET_USER,{variables: {id: params.id}})

    // update user data
    const [updateAdmin, {error, data: success, loading}] = useMutation(UPDATE_ADMIN,{
        refetchQueries: [{query: GET_ALL_USERS, variables:{page: 1, perPage: 10}}],
        awaitRefetchQueries: true
    })

    useEffect(() => {
        if(userData){
            register("email", { value: userData.oneUser.email,required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ });
            register("password");
            register("name", {value: userData.oneUser.name, required: true });
        }
    }, [userData]);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger
      } = useForm();

    const onSubmit = data => {
        updateAdmin({variables:{name:data.name,email: data.email,password: data.password, id: params.id}})
    };


    useEffect(() => {
        if( success ){
            navigate("/users")
        }
    },[success])

    return (
        <div className='page'>

            <div className='header'>
                <h1>Edit Admin</h1>
            </div>

            <ResultHandler loading={userLoading} error={userError} />      

            {
                userData &&
                <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>

                <Grid.Row>
                    <Grid.Column style={{marginBottom: "20px"}}>
                        <Form.Field>
                            <Form.Input 
                                defaultValue={userData.oneUser.email}
                                name="email"
                                placeholder='Email' 
                                error={ errors.email ? {
                                    content: 'Email is required',
                                } : false}  
                                onChange={async (e, { name, value }) => {
                                    
                                    setValue("email", e.target.value);
                                    await trigger( name );
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column style={{marginBottom: "20px"}}>
                        <Form.Field>
                            <Form.Input 
                                defaultValue={userData.oneUser.name}
                                name="name"
                                placeholder='Name' 
                                error={ errors.name ? {
                                    content: 'Name is required',
                                } : false}  
                                onChange={async (e, { name, value }) => {
                                    
                                    setValue("name", e.target.value);
                                    await trigger( name );
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column style={{marginBottom: "20px"}}>
                        <Form.Field style={{marginBottom: "20px"}}>
                            <Form.Input 
                                type='password'
                                name="password"
                                placeholder='Password' 
                                error={ errors.password ? {
                                    content: 'Password is required',
                                } : false}  
                                onChange={async (e, { name, value }) => {
                                    
                                    setValue("password", e.target.value);
                                    await trigger( name );
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <ResultHandler error={error} success={success} successMsg="Stored Successfully." />
                    </Grid.Column>

                    <Grid.Column style={{display: "flex",justifyContent: "end"}}>
                        <Button type="submit">Create</Button>
                    </Grid.Column>

                </Grid.Row>

            </Form>
            }

        </div>
    )

}