import { useMutation,useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_CATEGORY,GET_ALL_CATEGORIES, GET_ALL_PARENT_CATEGORIES,UPDATE_CATEGORY } from "../graphql/categories";
import { useParams } from "react-router";
import {useNavigate} from 'react-router-dom';
import ResultHandler from "../shared-components/ResultHandler";
import { Button, Dropdown, Form, Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";

export default function EditCategory(){
    
    const navigate = useNavigate();

    // get category data

    const params = useParams()

    const {data: categoryData,loading: categoryLoading, error: categoryError} = useQuery(GET_CATEGORY,{variables: {id: params.id}})

    useEffect(() => {
        if( categoryData ){
            register("name", {
                value: categoryData.getCategory.name, required: true });
            //setParent(categoryData.getCategory.parent?.value)
        }
    },[categoryData])

    // set input values

    //const [parent, setParent] = React.useState(null);

    // handle update

    const [nameError,setNameError] = useState(false)

    const [updateCategory,{loading, error, data: success}] = useMutation(UPDATE_CATEGORY,{
        refetchQueries: [{ query: GET_ALL_PARENT_CATEGORIES },
            {query: GET_ALL_CATEGORIES, variables:{page: 1, perPage: 10}},
            {query: GET_CATEGORY,variables: {id: params.id}}
        ],
        awaitRefetchQueries: true
    })


    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger
      } = useForm();

    const onSubmit = data => {
        updateCategory({variables:{
            id: params.id,
            name: data.name,
            //parentCategoryId: parent
        }})
    };


    useEffect(() => {
        if( success ){
            navigate("/categories")
        }
    },[success])

    //const { data: categoriesData } = useQuery(GET_ALL_PARENT_CATEGORIES);

    return (
        <div className='page'>
            <div className='header'>
                <h1>Edit Category</h1>
            </div>
            <ResultHandler loading={categoryLoading} error={categoryError} />
            {
                categoryData &&
                <Form loading={loading} onSubmit={handleSubmit(onSubmit)}>

                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Form.Input 
                                defaultValue={categoryData.getCategory.name}
                                label="Name"
                                name="name"
                                placeholder='Name' 
                                error={ errors.name ? {
                                    content: 'Name is required',
                                } : false}  
                                onChange={async (e, { name, value }) => { 
                                    setValue("name", e.target.value);
                                    await trigger( name );
                                }}
                                style={{marginBottom: "15px"}}
                            />
                        </Form.Field> 
                    </Grid.Column>
                    
                    {/*categoriesData && 
                    <Grid.Column>
                        <Form.Field>
                            <label>Parent Category</label>
                            <Dropdown 
                                placeholder='Select Category' 
                                onChange={(e,v) => {
                                    setParent(v.value)
                                }}
                                defaultValue={categoryData.getCategory.parent?.id}
                                clearable 
                                search 
                                selection 
                                options={categoriesData.getParentCategories.map(c => {return {key: c.id, value: c.id, text: c.name}})} 
                            />
                        </Form.Field>
                    </Grid.Column>
                            */}
                    
                    <Grid.Column>
                        <ResultHandler error={error} success={success} successMsg="Updated Successfully." />
                    </Grid.Column>

                    <Grid.Column style={{display: "flex",justifyContent: "end"}}>
                        <Button type="submit">Update</Button>
                    </Grid.Column>

                   

                </Grid.Row>

                </Form>
            }

        </div>
    )

}