import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient,ApolloProvider,  createHttpLink, InMemoryCache } from '@apollo/client';
import { Provider } from 'react-redux'
import store from './Dashboard/store/store'
import { setContext } from '@apollo/client/link/context';
import './semantic/semantic.css'
import { createUploadLink } from "apollo-upload-client";

const httpLink = createHttpLink({
  uri: 'https://as2alback.beeinteractivemedia.com/graphql',
});

const uploadLink = createUploadLink({ 
  uri: "https://as2alback.beeinteractivemedia.com/graphql" 
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('access_token');
  return {
    headers: {
      ...headers,
      Authorization: token ? token : "",
      'apollo-require-preflight': true
    }
  }
});



const client = new ApolloClient({
  //link: authLink.concat(httpLink).concat(uploadLink),
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache()
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApolloProvider client={client}>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
