
import "./Login.css"
import { useForm } from "react-hook-form";
import {useMutation} from "@apollo/client"
import { LOGIN } from "../graphql/auth";
import {  useDispatch } from 'react-redux'
import { authActions } from "../store/auth";
import { useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import { Button, Input, Form } from 'semantic-ui-react'
import { Grid } from 'semantic-ui-react'
import ResultHandler from "../shared-components/ResultHandler";

export default function Login(){

    let router = useNavigate ()

    useEffect(() => {
        register("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ });
        register("password", { required: true });
    }, []);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger
      } = useForm();

    const auth = useDispatch()

    const onSubmit = data => {
        login({variables:{email: data.email,password: data.password}})
    };

    const [login, {error, loading, data}] = useMutation(LOGIN)

    useEffect(()=>{
        if(data){
            console.log(data)
            auth(authActions.loginHandler(data.adminLogin.token))
            router("/users")
        }
    },[loading])


    return(

        <div className="authPage">
            
            <div className="authBox">

                <h2 style={{marginBottom: 20, textAlign: "center"}}>Esaal</h2>

                <Form loading={loading} onSubmit={handleSubmit(onSubmit)}>
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field style={{marginBottom: "20px"}}>
                                    <Form.Input 
                                        name="email"
                                        placeholder='Email' 
                                        error={ errors.email ? {
                                            content: 'Email is required',
                                        } : false}  
                                        onChange={async (e, { name, value }) => {
                                            
                                            setValue("email", e.target.value);
                                            await trigger( name );
                                        }}
                                    />
                                </Form.Field>
                                <Form.Field style={{marginBottom: "20px"}}>
                                    <Form.Input 
                                        type='password'
                                        name="password"
                                        placeholder='Password' 
                                        error={ errors.password ? {
                                            content: 'Password is required',
                                        } : false}  
                                        onChange={async (e, { name, value }) => {
                                            
                                            setValue("password", e.target.value);
                                            await trigger( name );
                                        }}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column style={{display: "flex",justifyContent: "end"}}>
                                <ResultHandler error={error} />
                            </Grid.Column>
                            <Grid.Column style={{display: "flex",justifyContent: "end"}}>
                                <Button type="submit" style={{margin: "0px"}}>Sign In</Button>
                            </Grid.Column>
                            
                        </Grid.Row>
                    </Grid>
                </Form>
            </div> 
        </div>

 
    )

}