
import { useMutation } from '@apollo/client';
import { Button, Table } from 'semantic-ui-react';
import { APPROVE_QUESTION, REJECT_QUESTION } from '../graphql/questions';
import ApproveModal from './ApproveModal';

export default function ApproveColumn({question, page}){
    
  const [handleApprove,{loading: approveLoading,error: approveError, data: approveSuccess}] = useMutation(APPROVE_QUESTION)

  const [handleReject,{loading: rejectLoading,error: rejectError, data: rejectSuccess}] = useMutation(REJECT_QUESTION)

    return(
        <Table.Cell>
            {
            !question.isApproved &&
            <>
                
                {question.category && question.isAnswered && <Button color="green" loading={approveLoading} onClick={() => {
                    handleApprove({variables: {id: question.id}})
                }}>
                    Approve      
                </Button>}

                {!question.category && question.isAnswered && 
                    <ApproveModal question={question} page={page} />
                }

                { !question.isAnswered &&
                    <div>Can't be approved</div>
                }
            
            </>
            }
            { question.isApproved &&
            <Button color='red' loading={rejectLoading} onClick={() => {handleReject({variables: {id: question.id}})}}>
                Reject      
            </Button>
            }
        </Table.Cell>
    )
}