
import { Navigate, Redirect } from "react-router-dom";
import Layout from "../Dashboard/layout/admin-layout/Layout";
import { useSelector, useDispatch } from 'react-redux'

export default function ProtectedRoute({ isProtected, children }){

    let loggedIn = useSelector((state) => state.auth.isLogged);

    if( isProtected ){

        if( loggedIn ){
            return (
                <Layout>
                    {children}
                </Layout>
            )
        }else{
            return (
                <Navigate to="/login" replace />
            )
        }

    }else{

        if( loggedIn ){
            return(
                <Navigate to="/users" replace />
            )
        }else{
            return (
                <>
                    {children}
                </>
            )
        }

    }

}