
import { gql } from "@apollo/client";

export const LOGIN = gql` 
  mutation login($email: String!, $password: String!){
    adminLogin(email: $email,password: $password){
      token
      admin{
        id
        name
        email
        password
        age
      }
    }
  }
`;