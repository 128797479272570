

import * as React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import "./DeleteModal.css"

export default function DeleteModal({ handleConfirm, success }) {

  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if(success){
      setOpen(false)
    }
  },[success])

  return (
    <Modal
    size="tiny"
    style={{margin: "0px !important"}}
      open={open}
      trigger={<Button color="red"><Icon name="trash alternate" style={{margin: "0px"}} /></Button>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header content='Are You Sure ?!' />
      <Modal.Content>
          <p>Are you sure you want to delete this</p>
        </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={() => setOpen(false)}>
          <Icon name='remove' /> Cancel
        </Button>
        <Button positive onClick={() => {
          handleConfirm()
        }}>
          <Icon name='checkmark' /> Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
