import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import { UPLOAD_IMG } from '../graphql/questions';
import ResultHandler from '../shared-components/ResultHandler';
import { useMutation } from '@apollo/client';
import { useState } from 'react';


export const ChooseModal = ({open, handleClose, handleChooseUrl}) => {

    const [handleUpload, {loading, error, data: success}] = useMutation(UPLOAD_IMG, {
        onCompleted: (data) => {
            handleChooseUrl(data.upload[0])
            handleClose()
        },
    })

    const [images, setImages] = useState(null)

    return (
    <Modal
        size="tiny"
        style={{margin: "0px !important"}}
        open={open}
        onClose={handleClose}
    >
        <Header content='Upload Image' />
        <Modal.Content>
            <input type={"file"} onChange={(e) => setImages([e.target.files[0]])} />
            <ResultHandler error={error} success={success} successMsg="Uploaded Successfully." />
        </Modal.Content>
        <Modal.Actions>
            {!loading && 
            <>
            <Button negative onClick={handleClose}>
                <Icon name='remove' /> Cancel
            </Button>
            <Button positive onClick={() => {handleUpload({variables: {images: images}})}}>
                <Icon name='checkmark' /> Confirm
            </Button>
            </>}
            <ResultHandler loading={loading} />

        </Modal.Actions>
    </Modal>
  );


}