import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Input, Menu } from 'semantic-ui-react'
import { Icon } from 'semantic-ui-react'
import { authActions } from "../../store/auth";

export default function HeaderMenu({handleToggleSidebar, visible}){


  let auth = useDispatch();
  let router = useNavigate ()

  function handle_logout(){
      auth(authActions.logoutHandler())
      router("/login");
  }

  return (
    <Menu style={{border: "0px", borderRadius: "0px"}}>
      <Menu.Item
        name='home'
        active={visible}
        onClick={handleToggleSidebar}
        icon
      >
        <Icon name="bars" />
      </Menu.Item>
      
      <Menu.Menu position='right'>
        <Menu.Item>
          <Input icon='search' placeholder='Search...' />
        </Menu.Item>
        <Menu.Item
          name='logout'
          onClick={handle_logout}
        />
      </Menu.Menu>
    </Menu>
  )

    
}