
import { useMutation } from '@apollo/client';
import { Button, Icon, Table } from 'semantic-ui-react';
import { SET_QUESTION_PRIORITY } from '../graphql/questions';

export default function PriorityColumn({question}){
    
  const [handleUpdate,{loading,error, data: success}] = useMutation(SET_QUESTION_PRIORITY)

    return(
        <Table.Cell>
            {question.priority === 100 &&
            
                <Button color='red' loading={loading} onClick={() => {
                    handleUpdate({variables: {id: question.id, priority: 0}})
                }}>
                    <Icon name="long arrow alternate down" style={{margin: "0px"}} />         
                </Button>
            
            }
            {question.priority < 100 &&
                <Button loading={loading} onClick={() => {handleUpdate({variables: {id: question.id, priority: 100}})}}>
                    <Icon name="long arrow alternate up" style={{margin: "0px"}} />     
                </Button>
            }
        </Table.Cell>
    )
}