
import { useMutation } from '@apollo/client';
import { Button, Icon, Table } from 'semantic-ui-react';
import { GET_ALL_QUESTIONS, SET_QUESTION_PINNED } from '../graphql/questions';

export default function PinColumn({question, page}){
    
  const [handleUpdate,{loading,error, data: success}] = useMutation(SET_QUESTION_PINNED,{
    refetchQueries: [
        {query: GET_ALL_QUESTIONS, variables: {page: page+1, perPage: 10}}
    ],
    awaitRefetchQueries: true
  })

    return(
        <Table.Cell>
            {question.isPinned &&
            
                <Button color='red' loading={loading} onClick={() => {handleUpdate({variables: {questions: [{id: question.id, isPinned: question.isPinned}]}})}}>
                     
                    <Icon name='remove' style={{margin: "0px"}} />   
                </Button>
            
            }
            {!question.isPinned &&
                <Button loading={loading} onClick={() => {handleUpdate({variables: {questions: [{id: question.id, isPinned: question.isPinned}]}})}}>
                    <Icon name='pin' style={{margin: "0px"}} />
                </Button>
            }
        </Table.Cell>
    )
}