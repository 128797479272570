import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_USERS } from '../graphql/users';
import { Button } from 'semantic-ui-react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'
import { Pagination } from 'semantic-ui-react'
import ResultHandler from '../shared-components/ResultHandler';

const columns = [
  { id: 'id', label: 'Id', minWidth: 170 },
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'email',label: 'Email',minWidth: 170,align: 'center' },
  { id: 'action',label: 'Action',minWidth: 170,align: 'center' },

];

export default function UsersList() {

  const navigate = useNavigate()

  const [page, setPage] = React.useState(0);

  const handlePaginationChange = (e, { activePage }) => setPage(activePage-1)

  const { loading, error, data } = useQuery(GET_ALL_USERS,{variables: {page: page+1, perPage: 10, type: "admin"}});
  
  return (
    <div className='page'>
      
      <div className='header'>
        <h1>Admins</h1>
        <Button onClick={() => {navigate('/add-user')}}>
            Add
        </Button>
      </div>

{ data?.allUsers?.users.length > 0 &&
  <div style={{ width: '100%', overflow: 'hidden' }}>
        <Table style={{ maxHeight: 440 }} celled>
          <Table.Header>
            <Table.Row>
              {columns.map((column) => (
                <Table.HeaderCell
                key={column.label}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.allUsers.users.map((row) => {
                return (
                  <Table.Row key={row.id}>
                    {columns.map((column) => {
                      if( column.id !== "action" ){
                        const value = row[column.id];
                        return (
                          <Table.Cell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </Table.Cell>
                        );
                      }
                    })}
                     <Table.Cell>
                      <Button color='blue' style={{marginRight: '10px'}} onClick={() => {navigate("/edit-user/"+row['id'])}}>
              
                          Edit
                        
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <div style={{display: "flex",justifyContent: "end"}}>  
      <Pagination  
        activePage={page+1}
        onPageChange={handlePaginationChange}    
        totalPages={data.allUsers.pageInfo.totalPages} 
      />
    </div>
      
  </div>
}
<ResultHandler empty={data?.allUsers?.users.length === 0} emptyMsg="No Users Found." error={error} loading={loading} />

    
    </div>
    
  );
}
