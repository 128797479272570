import * as React from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_QUESTIONS, DELETE_QUESTION } from '../graphql/questions';
import DeleteModal from '../shared-components/DeleteModal';
import { Button, Icon, Label, Pagination, Table } from 'semantic-ui-react';
import ResultHandler from '../shared-components/ResultHandler';
import ApproveColumn from './ApproveColumn';
import PriorityColumn from './PriorityColumn';
import PinColumn from './PinColumn';
import placeholder from "../../assets/imgs/placeholder.png"
const columns = [
  { id: 'text', label: 'Text', width: 4 },
  { id: 'img', label: 'Image', width: 1 },

  { id: 'Category',label: 'Category',width: 1},
  { id: 'isApproved',label: 'Approved',width: 1},
  { id: 'priority',label: 'Priority',width: 1},
  { id: 'isPinned',label: 'Pinned',width: 1},
  { id: 'action',label: 'Action',width: 2}
];

export default function QuestionsList() {

  const navigate = useNavigate()

  const [page, setPage] = React.useState(0);
  
  const handlePaginationChange = (e, { activePage }) => setPage(activePage-1)

  const [handle_delete_question,{data: deleteSuccess, error: deleteError, loading: deleteLoading}] = useMutation(DELETE_QUESTION,{
    refetchQueries: [
        { query: GET_ALL_QUESTIONS, variables: {page: page+1,perPage: 10} },
    ],
    awaitRefetchQueries: true
})

  const { loading, error, data } = useQuery(GET_ALL_QUESTIONS,{variables: {page: page+1,perPage: 10}});

  return (

    <div className='page'>
      
      <div className='header'>
        <h1>Questions</h1>
        <Button variant="contained" onClick={() => {navigate('/add-question')}}>
          Add
        </Button>
      </div>

      { data?.getQuestions.questions.length > 0 &&
  <div style={{ width: '100%', overflow: 'hidden' }}>
      
      <Table style={{ maxHeight: 440 }} celled>
          <Table.Header>
            <Table.Row>
              {columns.map((column) => (
                <Table.HeaderCell key={column.label} width={column.width}>
                  {column.label}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.getQuestions.questions.map((row) => {
                return (
                  <Table.Row key={row.id}>
                   
                    <Table.Cell>
                      <span style={{marginBottom: "15px", display: "block"}}>{row.text}</span>
                      { row.isAnswered &&
                        <Label color='green' >
                          Answered
                        </Label>
                      }
                        { !row.isAnswered &&
                        <Label>
                          Not Answered
                        </Label>
                      }
                    </Table.Cell>
                    <Table.Cell>
                        <img alt="" src={row['image_urls'].length > 0 ? row['image_urls'][0] : placeholder} style={{width: "40px", height: "40px", borderRadius: "7px"}} />
                    </Table.Cell>
                    <Table.Cell>
                          { row['category'] &&
                            <div>{ row['category'].name}</div>
                          }
                          { !row['category'] &&
                            <div>Not Found</div>
                          }
                    </Table.Cell>
                    
                    <ApproveColumn question={row} page={page} />
                    <PriorityColumn question={row} />
                    <PinColumn question={row} page={page} />
                    <Table.Cell>
                          <Button color='green' onClick={() => {navigate("/answer-question/"+(row['answer'] ? row['answer']['id'] : row['id']))}}>
                            <Icon name='pencil alternate' style={{margin: "0px"}} />
                          </Button>
                        <Button color='blue' onClick={() => {navigate("/edit-question/"+row['id'])}}>
                          <Icon name='edit outline' style={{margin: "0px"}} />
                        </Button>
                        <DeleteModal 
                          success={deleteSuccess}
                          handleConfirm={(e) => {
                            handle_delete_question({variables: {id: row['id']}})
                          }} 
                        />
                    </Table.Cell> 
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <div style={{display: "flex",justifyContent: "end"}}>  
          <Pagination 
            activePage={page+1}
            onPageChange={handlePaginationChange}    
            totalPages={data.getQuestions.pageInfo.totalPages} 
          />
        </div>
      
    </div>
}



<ResultHandler empty={data?.getQuestions.questions.length === 0} emptyMsg="No Questions Found." error={error} loading={loading} />


    </div>
    
  );
}
