import { useMutation } from "@apollo/client";
import {CREATE_ADMIN, GET_ALL_USERS} from "../graphql/users"
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import { useEffect } from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import ResultHandler from "../shared-components/ResultHandler";


export default function AddUser(){

    const navigate = useNavigate();

    useEffect(() => {
        register("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ });
        register("password", { required: true });
        register("name", { required: true });
    }, []);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger
      } = useForm();

    const [storeAdmin, {error, data: success, loading}] = useMutation(CREATE_ADMIN,{
        refetchQueries: [{query: GET_ALL_USERS, variables:{page: 1, perPage: 10, type: "admin"}}],
        awaitRefetchQueries: true
    })


    const onSubmit = data => {
        storeAdmin({variables:{name:data.name,email: data.email,password: data.password}})
    };

    useEffect(() => {
        if( success ){
            navigate("/users")
        }
    },[success])

    return (
        <div className='page'>
            <div className='header'>
                <h1>Add User</h1>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)} loading={loading}>

                <Grid.Row>
                    <Grid.Column style={{marginBottom: "20px"}}>
                        <Form.Field>
                            <Form.Input 
                                name="email"
                                placeholder='Email' 
                                error={ errors.email ? {
                                    content: 'Email is required',
                                } : false}  
                                onChange={async (e, { name, value }) => {
                                    
                                    setValue("email", e.target.value);
                                    await trigger( name );
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column style={{marginBottom: "20px"}}>
                        <Form.Field>
                            <Form.Input 
                                name="name"
                                placeholder='Name' 
                                error={ errors.name ? {
                                    content: 'Name is required',
                                } : false}  
                                onChange={async (e, { name, value }) => {
                                    
                                    setValue("name", e.target.value);
                                    await trigger( name );
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column style={{marginBottom: "20px"}}>
                        <Form.Field style={{marginBottom: "20px"}}>
                            <Form.Input 
                                type='password'
                                name="password"
                                placeholder='Password' 
                                error={ errors.password ? {
                                    content: 'Password is required',
                                } : false}  
                                onChange={async (e, { name, value }) => {
                                    
                                    setValue("password", e.target.value);
                                    await trigger( name );
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    
                    <Grid.Column>
                        <ResultHandler error={error} success={success} successMsg="Stored Successfully." />
                    </Grid.Column>

                    <Grid.Column style={{display: "flex",justifyContent: "end"}}>
                        <Button type="submit">Create</Button>
                    </Grid.Column>

                </Grid.Row>

            </Form>

        </div>
    )

}