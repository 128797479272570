import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import './App.css';
import NotFoundPage from './Dashboard/NotFoundPage';
import { protected_routes, public_routes } from './routes/AllRoutes';
import ProtectedRoute from './routes/ProtectedRoute';

function App() {
  return (
    <React.Fragment>
      <Routes>
        { 
          protected_routes.map(r => {
            return <Route key={r.path} path={r.path} element={<ProtectedRoute isProtected={true}>{r.element}</ProtectedRoute>} />
          })
        }
        { 
          public_routes.map(r => {
            return <Route key={r.path} path={r.path} element={<ProtectedRoute isProtected={false}>{r.element}</ProtectedRoute>} />
          })
        }
        <Route path='/' element={<Navigate to="/users" />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
