
import { gql } from "@apollo/client";

export const GET_ALL_CATEGORIES = gql` 
  query get_all_categories($page: Int!, $perPage: Int){
    getAllCategories(page: $page, perPage: $perPage){
      categories{
        id
        name
        isPinned
        isTrending
      }
      pageInfo {
        totalItems
        totalPages
        currentPage
      }
    }
  }
`;

export const GET_ALL_PARENT_CATEGORIES = gql` 
  query get_all_parent_categories{
    getRootCategories{
        id
        name
    }
  }
`;

export const CREATE_CATEGORY = gql` 
mutation create_category($name: String!,$parentCategoryId: ID ){
    createCategory(name: $name, parentCategoryId: $parentCategoryId) {
        id
        name
    }
}`

export const DELETE_CATEGORY = gql` 
mutation delete_category($id: ID! ){
  deleteCategory(id: $id) {
        id
        name
    }
}`

export const UPDATE_CATEGORY = gql` 
mutation update_category($id: ID!,$name: String,$parentCategoryId: ID){
  updateCategory(id: $id, name: $name, parentCategoryId: $parentCategoryId) {
        id
        name
    }
}`

export const GET_CATEGORY = gql` 
query get_category($id: ID!){
    getCategory(id: $id) {
        id
        name
        parent{
          id
          name
        }
    }
}`

export const SET_CATEGORY_PINNED = gql`
mutation adminSetPinnedCategories($ids: [ID!]) {
  adminSetPinnedCategories(ids: $ids)
}
`;

export const SET_CATEGORY_TRENDING = gql`
mutation adminSetTrendingCategories($ids: [ID!]) {
  adminSetTrendingCategories(ids: $ids)
}
`;
