import { useMutation,useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_ALL_PARENT_CATEGORIES } from "../graphql/categories";
import { GET_QUESTION, UPDATE_QUESTION, GET_ALL_QUESTIONS } from "../graphql/questions";
import { useParams } from "react-router";
import {useNavigate} from 'react-router-dom';
import { Button, Dropdown, Form, Grid } from "semantic-ui-react";
import ResultHandler from "../shared-components/ResultHandler";
import { useForm } from "react-hook-form";
import "./EditQuestion.css"
import ImgCard from "./ImgCard";
import { ChooseModal } from "../media-manager/ChooseImageModal";

export default function EditQuestion(){
    
    const navigate = useNavigate();

    // get question data

    const params = useParams()

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger
      } = useForm();

    const {data: questionData,loading: questionLoading, error: questionError} = useQuery(GET_QUESTION,{variables: {id: params.id}})

    // set input values

    const [parent, setParent] = React.useState(null);
    const [priority, setPriority] = React.useState(25);
    const [imgs, setImgs] = React.useState([])
    useEffect(() => {
        if( questionData ){
            register("text", {value: questionData.getQuestion.text, required: true });
            setParent(questionData.getQuestion.category ? questionData.getQuestion.category.id : null)
            setPriority(questionData.getQuestion.priority)
            setImgs(questionData.getQuestion.image_urls)
        }
    },[questionData])

    // handle update
    
    const [updateQuestion,{loading, error, data: success}] = useMutation(UPDATE_QUESTION,{
        refetchQueries: [
            {query: GET_ALL_QUESTIONS, variables:{page: 1, perPage: 10}},
            {query: GET_QUESTION,variables: {id: params.id}}
        ],
        awaitRefetchQueries: true
    })
    
    const onSubmit = data => {
        updateQuestion({variables: {
            id: params.id,
            text: data.text,
            categoryId: parent, 
            priority,
            image_urls: imgs
        }})
    };

    useEffect(() => {
        if( success ){
            navigate("/questions")
        }
    },[success])

    // get categories

    const { data: categoriesData } = useQuery(GET_ALL_PARENT_CATEGORIES);

    // upload image

    const [uploadOpen, setUploadOpen] = useState(false)

    return (
        <div className='page'>
            <div className='header'>
                <h1>Edit Question</h1>
            </div>
            
            <ResultHandler error={questionError} loading={questionLoading}/>

            { questionData &&
                <Form loading={loading} onSubmit={handleSubmit(onSubmit)}>
                    <Grid.Row>

                        <div style={{display: "flex", flexWrap: "wrap"}}>
                            { imgs.map((img, ind) => {
                                return <ImgCard src={img} handleRemove={() => {
                                    setImgs(imgs.filter((src, i) => i !== ind))
                                }} key={'img'+ind} />
                            })}

                            <div className="questionImg">
                                <i className="plus icon" style={{top: "50%", left :"50%", transform: "translate(-50%,-50%)"}} onClick={() => {setUploadOpen(true)}}></i> 
                            </div>
                        </div>

                        <Grid.Column style={{marginBottom: "15px"}}>
                            <Form.Field>
                                <Form.Input 
                                    defaultValue={questionData.getQuestion.text}
                                    label="Text"
                                    name="text"
                                    placeholder='Text' 
                                    error={ errors.text ? {
                                        content: 'Text is required',
                                    } : false}  
                                    onChange={async (e, { name, value }) => { 
                                        setValue("text", e.target.value);
                                        await trigger( name );
                                    }}
                                />
                            </Form.Field> 
                        </Grid.Column>
                        
                        {categoriesData && 
                            <Grid.Column style={{marginBottom: "15px"}}>
                                <Form.Field>
                                    <label>Category</label>
                                    <Dropdown 
                                        placeholder='Select Category' 
                                        onChange={(e,v) => {
                                            setParent(v.value)
                                        }}
                                        defaultValue={questionData.getQuestion.category ? questionData.getQuestion.category.id: null}
                                        search 
                                        selection 
                                        options={categoriesData.getRootCategories.map(c => {return {key: c.id, value: c.id, text: c.name}})} 
                                    />
                                </Form.Field>
                            </Grid.Column>
                        }            

                        <Grid.Column>
                            <Form.Field>
                                <label>Priority</label>
                                <Dropdown 

                                    placeholder='Select Priority' 
                                    onChange={(e,v) => {
                                        setPriority(v.value)
                                    }}
                                    defaultValue={questionData.getQuestion.priority || 25}
                                    search 
                                    selection 
                                    options={[0,25,50,75,100].map(c => {return {key: c, value: c, text: c}})} 
                                />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column style={{marginBottom: "15px"}}>
                            <ResultHandler error={error} success={success} successMsg="Updated Successfully." />
                        </Grid.Column>

                        <Grid.Column style={{display: "flex",justifyContent: "end"}}>
                            <Button type="submit">Update</Button>
                        </Grid.Column>

                    </Grid.Row>
                </Form>
            }

            {uploadOpen && <ChooseModal open={uploadOpen} handleClose={() => setUploadOpen(false)} handleChooseUrl={(url) => {
                setImgs([...imgs, url])
            }} />}
        

        </div>
    )

}